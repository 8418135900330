<template>
    <div class="home-container">
      <nav class="navbar">
        <div class="logo">LeadFormCreator</div>
        <ul class="nav-links">
          <li><router-link to="/">Dashboard</router-link></li>
          <li><router-link to="/create">New Form</router-link></li>
          <li><router-link to="/logout">Logout</router-link></li>
        </ul>
      </nav>
      <header>
        <h1>Welcome to LeadFormCreator</h1>
        <p>Here you can manage all your lead capture forms. Edit existing forms or copy their links for sharing.</p>
      </header>
      <div class="form-list">
        <div v-for="(form, index) in forms" :key="index" class="form-item">
          <h2>{{ form.title }}</h2>
          <p>Created on: {{ form.date }}</p>
          <button @click="editForm(form.id)">Edit</button>
          <button @click="copyLink(form.id)">Copy Link</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  
  const router = useRouter();
  const forms = ref([]);
  
  onMounted(() => {
    console.log('Mounted HomePage component');
    const savedForms = Object.keys(localStorage).reduce((acc, key) => {
      if (!isNaN(key)) { // Verifica se a chave é um número
        try {
          const form = JSON.parse(localStorage.getItem(key));
          if (form && typeof form === 'object') {
            console.log(`Form loaded: ${key}`, form);
            acc.push({ id: key, title: form.title || `Form ${key}`, date: form.date || new Date().toISOString().split('T')[0] });
          }
        } catch (e) {
          console.warn(`Error parsing form with key ${key}`, e);
        }
      }
      return acc;
    }, []);
    forms.value = savedForms;
    console.log('Forms:', forms.value);
  });
  
  const editForm = (id) => {
    console.log(`Editing form: ${id}`);
    router.push(`/edit/${id}`);
  };
  
  const copyLink = (id) => {
    const link = `${window.location.origin}/view/${id}`;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(link).then(() => {
        console.log(`Link copied for form: ${id}`);
        alert('Link copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy: ', err);
        fallbackCopyTextToClipboard(link);
      });
    } else {
      fallbackCopyTextToClipboard(link);
    }
  };
  
  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      alert('Link copied to clipboard');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  };
  </script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e74c3c;
    padding: 10px 20px;
    color: white;
  }
  
  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links li {
    display: inline;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  header h1 {
    font-size: 2em;
    color: #e74c3c;
  }
  
  header p {
    color: #666;
  }
  
  .form-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 25px;
  }
  
  .form-item {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    box-sizing: border-box;
  }
  
  .form-item h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .form-item p {
    color: #888;
    margin-bottom: 20px;
  }
  
  .form-item button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .form-item button:hover {
    background-color: #c0392b;
  }
  </style>
  