<template>
  <nav class="navbar">
    <div class="logo">LeadFormCreator</div>
    <ul class="nav-links">
      <li><router-link to="/">Dashboard</router-link></li>
      <li><router-link to="/logout">Logout</router-link></li>
    </ul>
  </nav>
  <div class="create-form">
    <div class="sidebar">
      <h2>Add Form Elements</h2>
      <button @click="addFieldButton('text')">Text Input</button>
      <button @click="addFieldButton('dropdown')">Dropdown</button>
      <button @click="addFieldButton('checkbox')">Checkbox</button>
      <button @click="addFieldButton('radio')">Radio Button</button>
      <button @click="addFieldButton('textarea')">Text Area</button>
    </div>
    <div class="form-builder">
      <h2>{{ editMode ? "Edit Your Form" : "Create Your Form" }}</h2>
      <div v-for="(field, index) in formFields" :key="index" class="form-field">
        <input v-model="field.label" placeholder="Field Label" />
        <select v-model="field.type">
          <option value="text">Text</option>
          <option value="textarea">Text Area</option>
          <option value="number">Number</option>
          <option value="dropdown">Dropdown</option>
          <option value="checkbox">Checkbox</option>
          <option value="radio">Radio</option>
        </select>
        <div
          v-if="
            field.type === 'dropdown' ||
            field.type === 'checkbox' ||
            field.type === 'radio'
          "
        >
          <div v-for="(option, optIndex) in field.options" :key="optIndex">
            <input
              :value="option"
              @input="updateOption($event.target.value, index, optIndex)"
              placeholder="Option"
            />
            <button @click="removeOption(index, optIndex)">
              Remove Option
            </button>
          </div>
          <button @click="addOption(index)">Add Option</button>
        </div>
        <button @click="removeField(index)">Remove Field</button>
      </div>
      <button @click="addField">Add Field</button>
      <button @click="saveForm">Save Form</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

const formFields = ref([]);
const route = useRoute();
const editMode = ref(false);

const addFieldButton = (type) => {
  addField(type);
};

const addField = (type = "text") => {
  formFields.value.push({ label: "", type, options: [] });
};

const removeField = (index) => {
  formFields.value.splice(index, 1);
};

const addOption = (index) => {
  formFields.value[index].options.push("");
};

const removeOption = (fieldIndex, optionIndex) => {
  formFields.value[fieldIndex].options.splice(optionIndex, 1);
};

const updateOption = (value, fieldIndex, optionIndex) => {
  formFields.value[fieldIndex].options[optionIndex] = value;
};

const saveForm = () => {
  const formId = route.params.formId || Date.now().toString(); // Use existing ID or generate a new one
  localStorage.setItem(formId, JSON.stringify(formFields.value));
  alert(`Form saved with ID: ${formId}`);
  router.push("/");
};

onMounted(() => {
  const editId = route.params.formId;
  console.log("Edit ID:", editId);
  if (editId) {
    editMode.value = true;
    const form = JSON.parse(localStorage.getItem(editId));
    if (form) {
      formFields.value = form;
    } else {
      alert("Form not found");
    }
  }
});
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e74c3c;
  padding: 10px 20px;
  color: white;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}
.create-form {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  border-right: 1px solid #ddd;
  height: 100vh;
  position: fixed;
}

.sidebar h2 {
  color: #e74c3c;
}

.sidebar button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.sidebar button:hover {
  background-color: #c0392b;
}

.form-builder {
  flex: 1;
  padding: 20px;
  margin-left: 300px;
}

.form-builder h2 {
  color: #333;
}

.form-field {
  margin-bottom: 20px;
}

.form-field input,
.form-field select {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-field button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px 0;
}

.form-field button:hover {
  background-color: #c0392b;
}

.form-builder button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px 0;
}

.form-builder button:hover {
  background-color: #c0392b;
}
</style>
