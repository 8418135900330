// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CreateForm from "../views/CreateForm.vue";
import ViewForm from "../views/ViewForm.vue";

const routes = [
  { path: "/", component: HomeView },
  { path: "/create", component: CreateForm },
  { path: "/edit/:formId", component: CreateForm },
  { path: "/view/:formId", component: ViewForm },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
