<template>
  <div>
    <FormViewer />
  </div>
</template>

<script>
import FormViewer from '../components/FormViewer.vue';

export default {
  components: {
    FormViewer,
  },
};
</script>
