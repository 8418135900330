<template>
  <div class="form-container">
    <h2>Form Preview</h2>
    <div class="form-preview" v-if="formFields.length">
      <div v-for="(field, index) in formFields" :key="index" class="form-field">
        <label>{{ field.label }}</label>
        <input
          v-if="field.type === 'text'"
          type="text"
          v-model="field.response"
        />
        <input
          v-if="field.type === 'number'"
          type="number"
          v-model="field.response"
        />
        <textarea
          v-if="field.type === 'textarea'"
          v-model="field.response"
          placeholder="Enter text here"
        ></textarea>

        <select v-if="field.type === 'dropdown'" v-model="field.response">
          <option
            v-for="(option, optIndex) in field.options"
            :key="optIndex"
            :value="option"
          >
            {{ option }}
          </option>
        </select>

        <div v-if="field.type === 'checkbox'" class="checkbox-group">
          <div v-for="(option, optIndex) in field.options" :key="optIndex">
            <input type="checkbox" :value="option" v-model="field.response" />
            <label>{{ option }}</label>
          </div>
        </div>

        <div v-if="field.type === 'radio'" class="radio-group">
          <div v-for="(option, optIndex) in field.options" :key="optIndex">
            <input
              type="radio"
              :name="`radio-${index}`"
              :value="option"
              v-model="field.response"
            />
            <label>{{ option }}</label>
          </div>
        </div>
      </div>
      <button class="submit-button" @click="submitForm">Submit</button>
    </div>
    <div v-else>
      <p>Loading form...</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

const formFields = ref([]);
const route = useRoute();

onMounted(() => {
  console.log("Route:", route);
  const formId = route.params.formId;
  console.log("Edit ID:", formId);
  const form = localStorage.getItem(formId);
  console.log("Form:", form);
  if (form) {
    formFields.value = JSON.parse(form).map((field) => ({
      ...field,
      response: field.type === "checkbox" ? [] : "",
    }));
  } else {
    alert("Form not found");
  }
});

const submitForm = async () => {
  const data = {
    formFields: formFields.value.map((field) => ({
      label: field.label,
      type: field.type,
      options: field.options,
      response: field.response,
    })),
  };

  try {
    const response = await fetch(
      "https://webhook-test.com/9e99f429be4afa24ba2c37da6bcb089b",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      alert("Form submitted successfully!");
    } else {
      const errorData = await response.json();
      alert(`Error submitting form: ${errorData.message}`);
    }
  } catch (error) {
    alert(`Error submitting form: ${error.message}`);
  }
};
</script>
<style scoped>
.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.form-preview {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.form-field {
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px;
}

.form-field label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-field input[type="text"],
.form-field input[type="number"],
.form-field textarea,
.form-field select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkbox-group,
.radio-group {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.checkbox-group label,
.radio-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.checkbox-group input,
.radio-group input {
  margin-right: 10px;
}

.submit-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submit-button:hover {
  background-color: #c0392b;
}
</style>
